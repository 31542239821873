import React, { useState, useEffect } from "react";
import Layout from "./../../components/Layout";
import OffresUtilisees from "./../../nav/Utilisateurs/OffresUtilisees";
import { Helmet } from "react-helmet";
import { getUser } from "./../../auth";
import { Error403 } from "./../../nav/Error403";
import { TailSpin } from "react-loader-spinner";

const HandleUserStates = ({ user }) => {
  if (user === undefined) {
    return (
      <div style={{ textAlign: "center" }}>
        <TailSpin color="#FF8000" height={200} width={200} />
      </div>
    );
  } else if (
    user &&
    (user.subscription.type === "freemium" ||
      user.subscription.type === "monthly" ||
      user.subscription.type === "yearly")
  ) {
    return <OffresUtilisees usedOffers={user.usedOffers} />;
  } else {
    return <Error403 />;
  }
};

const OffresUtiliseesPage = () => {
  const [user, setUser] = useState(undefined);
  useEffect(() => {
    getUser(setUser);
  }, []);

  return (
    <Layout user={user}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>WYNIT | Offres utilisées</title>
        <meta
          name="description"
          content="WYNIT, les bons plans marseillais pour les 15-30 ans. Tu trouveras ici les offres que tu as déjà utilisées."
        />
        <meta name="keywords" content="réductions,jeunes,offres,utilisees" />
        <link
          rel="canonical"
          href="https://wynit.com/utilisateurs/offres-utilisees"
        />
      </Helmet>
      <HandleUserStates user={user} />
    </Layout>
  );
};

export default OffresUtiliseesPage;
